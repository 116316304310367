import { cookieStorage, makePersisted } from '@solid-primitives/storage';
import { createContext, useContext } from 'solid-js';
import { createStore } from 'solid-js/store';
import { useTrackEvent } from '@troon/analytics';
import { isServer } from 'solid-js/web';
import type { ParentProps } from 'solid-js';

const experiments = {
	'golfpass+': { 'show golfpass+': 0.5 },
} as const;
type ExpKey = keyof typeof experiments;
type ExpBranch<K extends ExpKey> = keyof (typeof experiments)[K] | 'control';

type StoreData = Partial<Record<ExpKey, [ExpBranch<ExpKey>, boolean]>>;
type Store = ReturnType<typeof createStore<StoreData>>;
const Context = createContext<Store>();

export function FauxExperimentProvider(props: ParentProps) {
	const [store, setStore] = makePersisted(createStore({}), {
		name: 'tge',
		storage: cookieStorage.withOptions({
			expires: new Date(+new Date() + 1000 * 60 * 60 * 24 * 30),
			sameSite: 'Strict',
			secure: true,
		}),
		serialize(data) {
			return btoa(JSON.stringify(data));
		},
		deserialize(data) {
			try {
				return JSON.parse(atob(data));
			} catch {
				return {};
			}
		},
	});
	return <Context.Provider value={[store, setStore]}>{props.children}</Context.Provider>;
}

function getBranch<K extends ExpKey>(name: K): ExpBranch<K> {
	const branches = Object.entries(experiments[name]) as Array<[ExpBranch<K>, number]>;
	const val = Math.random();

	for (let i = 0, match = 0; i < branches.length; i++) {
		const b = branches[i]!;
		match += b[1];
		if (val < match) {
			return b[0];
		}
	}
	return 'control';
}

export function createExperimentBranch<K extends ExpKey>(name: K): ExpBranch<K> {
	const [store, setStore] = useContext(Context)!;
	const track = useTrackEvent();

	if (store[name]) {
		const [branch, tracked] = store[name];
		if (!isServer && !tracked) {
			track('bucketed', { experimentName: name, experimentBranch: branch });
			setStore(name, [branch, true]);
		}
		return branch;
	}

	const branch = getBranch(name);
	if (!isServer) {
		track('bucketed', { experimentName: name, experimentBranch: branch });
		setStore(
			name,
			// @ts-expect-error StoreSetter gets weird
			[branch, true],
		);
	}
	setStore(
		name,
		// @ts-expect-error StoreSetter gets weird
		[branch, !isServer],
	);
	return branch;
}

export function createIsExperimentBranch<K extends ExpKey>(name: K, branch: ExpBranch<K>): boolean {
	return createExperimentBranch(name) === branch;
}
